export default {
  apiBaseUrl: process.env.REACT_APP_API_ENDPOINT,
  analyticsKey: process.env.REACT_APP_ANALYTICS_KEY,
  errorLoggingKey: process.env.REACT_APP_ERRORS_LOGGING_KEY,
  hideTopBarLogoByURL: process.env.REACT_APP_HIDE_TOPBAR_LOGO_FOR_URL,
  theme: {
    demo: "false",
    askForTrackingConsent: "true",
    termsUrl: "https://www.makeree.com/terms",
    termsEnabled: "true",
    galleryEnabled: "false",
    customGalleryTitle: "",
    alwaysEnableCamera: "false",
    customTextFont: "",
    customTextFontUrl: "",
    customTitleFont: "",
    customTitleFontUrl: "",
    customCss: "",
    logo: "",
    logoUrl: "",
    disableMinimalMode: "false",
    disableHeaderLogo: "false",
    stepsLogoEnabled: "true",
    stepTextContentPercentage: "50",
    hideContactForm: "false",
    hideAskAQuestion: "false",
    hideLanguages: "false",
    hideFooter: "false",
    alwaysShowCurrentStepNumber: "false",
    defaultBackgroundColor: "#ffffff",
    defaultTextColor: "#000000",
    defaultIconsColor: "#12191F",
    controllerBackgroundColor: "#ffffff",
    controllerTextColor: "#12191F",
    buttonBackgroundColor: "#0a9ae9",
    buttonTextColor: "#ffffff",
    progressBarColor: "#f5a809",
    stepBackgroundColor: "#f5f8fa",
    stepTextBackgroundColor: "#ffffff",
    stepTextColor: "#000000",
    stepOpenTipColor: "#7f8c99",
    textStepBackgroundColor: "#0a9ae9",
    textStepTextColor: "#ffffff",
    splitDecisionTextColor: "#ffffff",
    splitDecisionBackgroundColor: "#53B8EF",
    splitDecisionButtonBackgroundColor: "#f5a809",
    splitDecisionButtonTextColor: "#ffffff",
    menuBackgroundColor: "#ffffff",
    menuTextColor: "#000000",
    menuCheckColor: "#0a9ae9",
    popupBackgroundColor: "#ffffff",
    popupTextColor: "#000000",
    customMaterialsTitle: "",
    galleryEmodji: "false",
  },
};
